document.addEventListener('DOMContentLoaded', function() {
  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#333399"
      },
      "button": {
        "background": "#ed6b6e",
        "text": "#ffffff"
      }
    },
    "position": "bottom-left",
    "content": {
      "message": '„This website uses cookies - for more information on this and your rights as a user, please see our privacy policy at the bottom of the page. Click on "End of page. Click "I agree" to accept cookies and visit our website directly.“',
      "dismiss": "Accept cookies",
      "link": "Learn more",
      "href": "legal-disclosure.html"
    }
  });
});
